(function (Matrix, $, undefined) {

  /* note: the notification api in chrome does not match the standard (this could be made a polyfill, but as it is, is just a custom api to hide the nasty) */
  var nativeNotification = window.Notification;
  var webkitNotification = window.webkitNotifications;
  Matrix.Notification = function (title, options) {
    return new nativeNotification(title, options);
  };

  var isFunction = function (obj) {
    return typeof obj === 'function';
  };

  Matrix.Notification.listener = null;
  Matrix.Notification.enabled = true;
  Matrix.Notification.supported = nativeNotification || webkitNotification;
  Matrix.Notification.pollUrl = null;
  Matrix.Notification.iconUrl = null;

  Matrix.Notification.getPermission = function () {
    if (nativeNotification) {
      if (nativeNotification.permission)
        return nativeNotification.permission;

      if (isFunction(nativeNotification.permissionLevel))
        return nativeNotification.permissionLevel();
    }

    if (webkitNotification && isFunction(webkitNotification.checkPermission)) {
      switch (webkitNotification.checkPermission()) {
        case 0:
          return 'granted';
        case 1:
          return 'default';
        case 2:
          return 'denied';
      }
    }

    return 'default';
  };

  Matrix.Notification.setPermission = function (permission) {
    if (nativeNotification && nativeNotification.permission)
      nativeNotification.permission = permission;
  };

  Matrix.Notification.requestPermission = function (callback) {
    if (nativeNotification && isFunction(nativeNotification.requestPermission))
      nativeNotification.requestPermission(callback);
    else if (webkitNotification && isFunction(webkitNotification.requestPermission))
      webkitNotification.requestPermission(callback);
  };

  Matrix.NotificationListener = function (intervalInMilliseconds) {
    var self = this;
    var timeout;
    var displayNotifications = function (notifications) {
      if (!notifications)
        return;

      if (typeof notifications !== 'object' || !notifications.length)
        return;

      for (var i = 0; i < notifications.length; i++) {
        var notification = notifications[i];
        var popup = new Matrix.Notification(notification.title, { body: notification.message, icon: Matrix.Notification.iconUrl });
        if (notification.url) {
          popup.onclick = function () {
            window.open(notification.url);
          };
        }
      }
    };

    var checkForNotifications = function () {
      if (!Matrix.Notification.pollUrl) {
        console.log('Matrix.Notification.pollUrl is not configured.');
        return;
      }

      $.ajax({
        url: Matrix.Notification.pollUrl,
        type: 'get',
        cache: false,
        success: function (data, textStatus, jqXHR) {
          displayNotifications(data);
        },
        error: function (jqXHR) {
          if (jqXHR.status == 0) {
            self.stop();
            return true;
          }

          console.error('An error occurred retrieving notifications: ' + jqXHR.responseText);
          return true;
        }
      });
    };

    this.start = function () {
      if (!Matrix.Notification.supported)
      {
        console.log('Notifications are not supported by your browser.')
        return;
      }
      console.log('Notification permission: "' + Matrix.Notification.getPermission() + '"');
      if (Matrix.Notification.getPermission() === 'denied') {
        console.log('Notification permission has been denied.');
        return;
      }

      checkForNotifications();
      timeout = setInterval(checkForNotifications, intervalInMilliseconds);
    };
    this.stop = function () {
      clearInterval(timeout);
    };
  };
})(window.Matrix = window.Matrix || {}, $, undefined);

$(function () {
  if (Matrix.NotificationListener) {
    Matrix.Notification.listener = new Matrix.NotificationListener(60000);
    if (Matrix.Notification.enabled)
      Matrix.Notification.listener.start();
  }
});